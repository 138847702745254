import React, { Children } from "react"
import Modal from "react-modal"


const PopUp = ({ children }) => {

  const [ modalIsOpen, setIsOpen ] = React.useState(false);
  
  const closeModal = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (navigator.maxTouchPoints == 0) {
      document.addEventListener("mouseout", mouseEvent, { passive: true });
    } else {
      const TouchTimeout = setTimeout(() => {
        setIsOpen(true);
      }, 10000);
      return () => {
        clearTimeout(TouchTimeout);
      };
    }
    return () => {
      if (navigator.maxTouchPoints == 0) {
        document.removeEventListener("mouseout", mouseEvent);
      }
    };
  }, [])

  const mouseEvent = e => {
    if (!e.toElement && !e.relatedTarget && e.clientY < 10) {
      document.removeEventListener("mouseout", mouseEvent);
      setIsOpen(true);
    }
  };
  
	return(
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false} //{LandingPageConfig.modal_outside_close ? false : true}
      contentLabel="Lead Modal"
    >
      <svg className="Modal_Close_Button" alt="Modal Close Button" onClick={closeModal} title="Close Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
      </svg>
      {children}
    </Modal>
  );
};

export default PopUp